import React, { HTMLAttributes } from 'react'
import { IClassNameProps } from '@bem-react/core'

import { cnLink } from '../cn'

export interface ILinkInnerProps
  extends IClassNameProps,
    HTMLAttributes<HTMLSpanElement> {}

export const LinkInner = ({
  children,
  className,
  ...props
}: ILinkInnerProps) => (
  <span {...props} className={cnLink('Inner', null, [className])}>
    {children}
  </span>
)
