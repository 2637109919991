import { withRegistry } from '@bem-react/di'
import { compose } from '@hmm/utils'
import { withPointerFocus } from '../../../hocs/withPointerFocus/withPointerFocus'
import { Link as LinkBase } from './Link'
import { linkRegistry } from './Link.registry'

export const Link = compose(
  withPointerFocus,
  withRegistry(linkRegistry)
)(LinkBase)

export * from './Link'
export * from './_pseudo'
export * from './_size'
