import { Registry } from '@bem-react/di'
import { LinkIcon } from '../Icon/Link-Icon'
import { LinkInner } from '../Inner/Link-Inner'
import { cnLink } from '../cn'

export interface ILinkRegistry {
  Icon: typeof LinkIcon
  Inner: typeof LinkInner
}

export const linkRegistryId = cnLink.displayName

export const linkRegistry = new Registry({ id: linkRegistryId })

linkRegistry.set('Icon', LinkIcon).set('Inner', LinkInner)
