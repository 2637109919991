import { ReactElement } from 'react'
import { cn, IClassNameProps } from '@hmm/utils'
import { IIconProps } from '@hmm/components/Icon'
import { cnLink } from '../cn'
import styles from './Link-Icon.module.css'

cn(cnLink, styles)

export type IconProvider = (className: string) => ReactElement<IIconProps>

export interface ILinkIconProps extends IClassNameProps {
  side?: 'left' | 'right'
  provider: IconProvider
}

export const LinkIcon = ({ side, provider, ...props }: ILinkIconProps) => {
  const className = cnLink('Icon', { side }, [props.className])

  return provider(className)
}
