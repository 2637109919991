import { GetServerSideProps } from 'next'
import LoginPage from '../views/auth_login/mobile'
import { backendProxy } from '../internal/backend-proxy'
import {
  dataFromBemBackend, getPublicEnv,
  pageMetaFromBemBackend,
} from "../internal/bem-data";
import { assertPageMetaContract, assertAppDataContract } from 'contracts/pages'
import { assertSocialLinkContract } from 'contracts/SocialLink'
import { merge } from '@sweet-monads/either'

export default LoginPage

export const getServerSideProps: GetServerSideProps = async ({ req, res, query}) => {
  const [data, fetched] = await backendProxy(req, res)
  if (data === null) {
    if (fetched.status === 404) {
      return { notFound: true }
    }
    return { props: { app: {}, meta: {} } }
  }

  // common GET request

  const pageData = dataFromBemBackend(data)
  const meta = assertPageMetaContract(pageMetaFromBemBackend(data)).mapLeft((e) => {
    throw e
  }).value

  const app = assertAppDataContract(pageData.app).mapLeft((e) => {
    throw e
  }).value

  const socials = Array.isArray(pageData.socials)
    ? merge(
        pageData.socials.map((social) => assertSocialLinkContract(social))
      ).mapLeft((e) => {
        throw e
      }).value || []
    : []
  const publicEnv = getPublicEnv()

  return { props: { app, meta, socials, query, publicEnv } }
}
