import React, {
  AnchorHTMLAttributes,
  DetailedHTMLFactory,
  RefObject
} from 'react'
import { useComponentRegistry } from '@bem-react/di'
import { cnLink } from './cn'

import { ILinkRegistry } from './Link.registry'
import { IconProvider } from './Icon/Link-Icon'

export type ContainerElement = HTMLSpanElement | HTMLAnchorElement

type ReactLinkElement = DetailedHTMLFactory<
  AnchorHTMLAttributes<ContainerElement>,
  ContainerElement
>

export interface ILinkProps extends AnchorHTMLAttributes<ContainerElement> {
  /**
   * Адрес ссылки. Если указано, то компонент будет оформлен тегом `a`, в противном случае — `spin`.
   *
   * Значение игнорируется при использовании модификатора `pseudo`.
   */
  href?: string

  /**
   * Выключение интерактивности ссылки.
   */
  disabled?: boolean
  /**
   * Иконка ссылки.
   */
  icon?: IconProvider

  /**
   * Иконка слева от текста ссылки.
   */
  iconLeft?: IconProvider

  /**
   * Иконка справа от текста ссылки.
   */
  iconRight?: IconProvider

  /**
   * Ссылка на корневой DOM-элемент компонента.
   */
  innerRef?: RefObject<ContainerElement>

  /**
   * Ссылка на DOM-элемент нативного контрола.
   */
  controlRef?: RefObject<ContainerElement>

  /**
   * Обязательное включение обертки для текста.
   */
  needInner?: boolean
}

/**
 * Компонент используется для создания ссылок.
 * @param {ILinkProps} props
 */
export const Link = ({
  title,
  href,
  target,
  icon,
  iconLeft,
  iconRight,
  needInner,
  disabled,
  rel,
  tabIndex,
  innerRef,
  controlRef,
  children,
  ...props
}: ILinkProps) => {
  const className = cnLink(null, [props.className])
  const { Icon, Inner } = useComponentRegistry<ILinkRegistry>(cnLink.displayName)

  // tslint:disable-next-line:no-any
  const TagName = (href ? 'a' : ('span' as any)) as ReactLinkElement
  let relationship = rel

  if (
    target === '_blank' &&
    rel !== undefined &&
    rel.indexOf('noopener') === -1
  ) {
    // Пользовательский атрибут имеет больший приоритет
    relationship = `${rel} noopener`
  }

  const iconLeftOrIcon = iconLeft || icon

  return (
    <TagName
      {...props}
      ref={innerRef || controlRef}
      href={disabled ? undefined : href}
      aria-disabled={disabled}
      target={target}
      rel={relationship}
      tabIndex={disabled ? (href ? -1 : undefined) : tabIndex}
      className={className}
    >
      {iconLeftOrIcon && (
        <Icon
          provider={iconLeftOrIcon}
          side={children && iconLeft ? 'left' : undefined}
        />
      )}
      {children &&
        (needInner || iconLeftOrIcon || iconRight ? (
          <Inner>{children}</Inner>
        ) : (
          children
        ))}
      {iconRight && (
        <Icon provider={iconRight} side={children ? 'right' : undefined} />
      )}
    </TagName>
  )
}

Link.displayName = cnLink.displayName
