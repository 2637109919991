import React from 'react'
import { cnAuthForm } from '../index'
import { Link as BaseLink } from '@hmm/components/Link'
import { useRoutes, withRouter } from 'modules/Router'

const Link = withRouter(BaseLink)

export const AuthFormReset = () => {
  const routes = useRoutes()
  return (
    <Link
      rel="nofollow"
      href={routes.auth.reset}
      className={cnAuthForm('LabelLink')}
    >
      Забыли пароль?
    </Link>
  )
}
