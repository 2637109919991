import React, { useCallback, useState } from 'react'
import { cnAuthForm, IAuthFields, IAuthFormProps } from '../index'
import { ISocialVendor } from '../../../../models/socials'
import { DefaultMessages } from 'shared/validation'
import { useUniqId } from '@hmm/hooks'
import { useSendForm } from '../service'
import { withBemMod } from '@hmm/utils'
import { AuthFormReset } from '@hmm/components/AuthForm/Reset'
import { Texts as t } from '../texts'
import { TextField } from '@hmm/components/TextField'
import { Button } from '@hmm/components/Button'
import { Message } from '@hmm/components/Message'
import { LoginVariants } from '@hmm/components/LoginVariants'
import { Typography } from '@mui/material'
import { AuthFormAlternateAction } from '../AlternateAction/AuthForm-AlternateAction'

export interface ILoginFields extends IAuthFields {
  login: string
  password: string
  remember: boolean
}

export interface IAuthFormViewLogin {
  view?: 'login'
  socials?: Array<ISocialVendor>
  query?: Partial<ILoginFields> & { variant?: string }
  onVariantChange?: (v: string) => void
}

function validate(values: ILoginFields) {
  const errors: Partial<ILoginFields> = {}
  if (!values.login) {
    errors.login = DefaultMessages.REQUIRED
  }
  if (!values.password) {
    errors.password = DefaultMessages.REQUIRED
  }
  return errors
}

function useInit(props: IAuthFormViewLogin & IAuthFormProps) {
  const id = useUniqId(props.id)
  const q = Object.assign({ login: '', remember: true }, props.query)
  const initialValues: ILoginFields = {
    _token: props.csrf,
    login: q.login,
    password: '',
    remember: q.remember
  }
  const { formik, ...formHandler } = useSendForm(props.action || '/login', {
    initialValues,
    validate
  })
  return { id, formik, formHandler }
}

export const withAuthFormViewLogin = withBemMod<
  IAuthFormViewLogin,
  IAuthFormProps
>(
  cnAuthForm,
  {
    view: 'login'
  },
  (AuthForm) =>
    ({ onVariantChange, ...props }) => {
      const { id, formik, formHandler } = useInit(props)
      const [variant, setVariant] = useState(props.query?.variant || '')
      const handleVariantChange = useCallback(
        (variant: string) => {
          setVariant(variant)
          onVariantChange && onVariantChange(variant)
        },
        [onVariantChange]
      )
      const onBackClick = useCallback(
        () => handleVariantChange(''),
        [handleVariantChange]
      )

      return (
        <AuthForm
          {...props}
          hasBack={Boolean(variant)}
          onBackClick={onBackClick}
          onSubmit={formik.handleSubmit}
        >
          <Typography variant="h2" className={cnAuthForm('Title')}>
            {t.loginTitle}
          </Typography>
          {formHandler.message && (
            <Message
              status={formik.status}
              size="l"
              className={cnAuthForm('Message')}
            >
              {formHandler.message}
            </Message>
          )}
          <LoginVariants
            variant={variant}
            onVariantChange={handleVariantChange}
            socials={props.socials || []}
            fields={
              <div className={cnAuthForm('Fields')}>
                <div key={1} className={cnAuthForm('Field')}>
                  <input
                    type="hidden"
                    name="username"
                    value={formik.values.login}
                  />
                  <TextField
                    id={`${id}-login`}
                    tabIndex={1}
                    fullWidth
                    label={t.loginLabel}
                    placeholder={t.loginPlaceholder}
                    autoComplete="current-login"
                    {...formik.getFieldProps('login')}
                    error={Boolean(formik.errors.login && formik.touched.login)}
                    helperText={formik.errors.login}
                  />
                </div>
                <div key={2} className={cnAuthForm('Field')}>
                  <TextField
                    id={`${id}-password`}
                    fullWidth
                    type="password"
                    tabIndex={2}
                    label={t.password}
                    placeholder={t.passwordPlaceholder}
                    autoComplete="current-password"
                    {...formik.getFieldProps('password')}
                    error={Boolean(
                      formik.errors.password && formik.touched.password
                    )}
                    helperText={formik.errors.password}
                  />
                </div>
                <input type="hidden" name="remember" value="1" />
                <div key={4} className={cnAuthForm('Field')}>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={formHandler.isSending || !formik.isValid}
                    className={cnAuthForm('Submit')}
                  >
                    {t.login}
                  </Button>
                </div>
                <AuthFormReset />
              </div>
            }
          />
          <div className={cnAuthForm('Footer')}>
            <AuthFormAlternateAction action="registration" />
          </div>
        </AuthForm>
      )
    }
)
