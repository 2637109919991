import React, { useCallback } from "react";
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { compose } from '@hmm/utils'
import {
  Page as PageBase,
  PageProps,
  withViewAuth
} from "../../ui/layouts/Page";
import { DS_AppData } from 'contracts/pages'
import { DS_SocialLink } from "contracts/SocialLink";
import { AuthForm as AuthFormBase, withAuthFormViewLogin, ILoginFields } from '@hmm/components/AuthForm'

const Page = compose(withViewAuth)(PageBase)
const AuthForm = compose(withAuthFormViewLogin)(AuthFormBase)

export interface LoginPageProps extends PageProps {
  app: DS_AppData
  socials?: DS_SocialLink[],
  query?: Record<string, string>
}

const LoginPage: NextPage<LoginPageProps> = ({ socials= [], app , query, ...props}) => {
  const router = useRouter()
  const onVariantChange = useCallback((variant: string) => {
    router.push(`/login?variant=${variant}`, undefined, { shallow: true })
  }, [router])
  return (
    <Page view="auth" {...props}>
      <AuthForm action="/login" view="login" csrf={app.csrf} socials={socials} query={query} onVariantChange={onVariantChange} />
    </Page>
  )
}
export default LoginPage
