import React, { ComponentType, PureComponent } from 'react'

let wrappedComponents = 0

export const withPointerFocus = <TProps extends {}>(
  WrappedComponent: ComponentType<TProps>
) =>
  class WithPointerFocus extends PureComponent<TProps> {
    timeoutId = 0
    isPointer = false

    componentDidMount() {
      wrappedComponents++

      if (wrappedComponents === 1) {
        window.addEventListener('blur', this.onBlur)
        document.addEventListener('keydown', this.onKeyDown)
        document.addEventListener('mousedown', this.onMouseDown)
        // В Chrome HTMLLabelElement после клика на себе производит синтетический
        // клик по связанному элементу, после чего на связанном элементе происходит focus.
        // При длинном клике isPointer успевает стать false, и вокруг элемента появляется
        // рамка фокуса. Обработчик на mouseup решает проблему.
        document.addEventListener('mouseup', this.onMouseDown)
        document.addEventListener('focus', this.onFocus, true)

        if (
          // Если компонент монтируется по клику в другой
          !document.body.classList.contains('pointerfocus') &&
          // на случай конфликта с i-bem
          !document.documentElement.classList.contains('pointerfocus')
        ) {
          document.body.classList.add('utilityfocus')
        }
      }
    }

    componentWillUnmount() {
      wrappedComponents--

      if (wrappedComponents === 0) {
        window.removeEventListener('blur', this.onBlur)
        document.removeEventListener('keydown', this.onKeyDown)
        document.removeEventListener('mousedown', this.onMouseDown)
        document.removeEventListener('mouseup', this.onMouseDown)
        document.removeEventListener('focus', this.onFocus, true)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }

    onKeyDown = () => {
      clearTimeout(this.timeoutId)
      this.isPointer = false
    }

    onMouseDown = () => {
      this.isPointer = true
      clearTimeout(this.timeoutId)
      // Используем setTimeout из window,
      // т.к. typescript думает, что это nodejs окружение.
      this.timeoutId = window.setTimeout(() => (this.isPointer = false), 600)
    }

    onFocus = () => {
      if (this.isPointer) {
        document.body.classList.add('pointerfocus')
        document.body.classList.remove('utilityfocus')
      } else {
        document.body.classList.add('utilityfocus')
        document.body.classList.remove('pointerfocus')
      }
    }

    onBlur = () => {
      window.addEventListener('focus', this.setIsPointerOnTabFocus, true)
    }

    setIsPointerOnTabFocus = () => {
      window.removeEventListener('focus', this.setIsPointerOnTabFocus, true)

      if (!document.body.classList.contains('pointerfocus')) {
        return
      }

      this.isPointer = true
      setTimeout(() => (this.isPointer = false))
    }
  }
